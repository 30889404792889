
/**
      * this file is auto generated by chopin-scripts.
      * you can check the project package.json 
      * to see how it was generated.
      */
    
import Meiosis from './meiosis';
import React from './react';
import ViewModels from './view-models';

export default {
        Meiosis,		React,		ViewModels
}
